.pageHeader {
  margin-bottom: 20px;
  margin-top: 15px;
}

.pageHeader .heading {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin: 0;
}

.pageHeader .heading svg {
  margin-right: 10px;
  margin-top: -5px;
}

.pageHeader .heading .fa,
.pageHeader .heading .fas {
  cursor: pointer;
  margin-right: 10px;
}

.pageHeader .sub_heading {
  padding-bottom: 0;
  margin-bottom: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #b5b5c3;
}

.text-small {
  font-size: 12px;
}

a:hover {
  text-decoration: none;
}

.full-height {
  display: flex;
  align-items: stretch;
}

.full-height .card {
  flex: 1;
}

.semicircle-container {
  text-align: center;
}

.server-details-container {
  margin-top: 15px;
}

.server-health-container {
  margin-top: 15px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.card {
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
}

.card .card-header {
  border-bottom: 1px solid #f1f3f6;
  font-family: Roboto;
  background: none;
}

.card .card-header .heading {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #3e3e3e;
  margin: 0 0 2px 0;
}

.card .card-header .sub-heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #b5b5c3;
  margin: 0;
}

.card .card-body.services {
  color: black;
}

.card .card-body.services .row {
  margin-top: 15px;
}

.card .card-body.services svg {
  margin-top: -3px;
}

.card .card-body.server-details-list .col-2 {
  padding-right: 0;
}

.card .card-body.server-details-list .col-10,
.card .card-body.server-details-list .col-8 {
  padding-left: 5px;
}

.card .card-body.server-details-list img {
  width: 30px;
}

.card .card-body.server-details-list p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #b5b5c3;
}

.card .card-body.server-details-list a:not(.btn) {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .card.server-health .progress-container {
    margin: 15px auto;
  }
}

.card.server-health .server-status {
  margin-top: -20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}

.card.server-health .server-status-name {
  margin: 8px auto;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
}

.card.server-health .server-status-subtext {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #b5b5c3;
}

.card.invoice {
  color: #3e3e3e;
}

.card.invoice p {
  color: #3e3e3e;
  line-height: 1.4rem;
}

.card.invoice td {
  color: #3e3e3e;
}

.card.invoice .brand-image {
  width: 40px;
}

.card.invoice .brand-text {
  display: inline-block;
  margin-top: 2px;
}

.card.invoice .address {
  vertical-align: top;
}

.card.invoice .address p {
  width: 300px;
}

.card.invoice .address .email {
  font-weight: bold;
}

.bg-striped {
  background-color: rgba(0, 0, 0, 0.05);
}

.mb-100 {
  margin-bottom: 100px;
}

.btn-theme {
  background: linear-gradient(90.12deg, #ac2ff1 0.08%, #7973fe 99.9%);
  box-shadow: 0px 8px 8px rgba(158, 65, 245, 0.3);
  border-radius: 24px;
  padding: 8px 20px;
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.btn-theme i {
  margin-left: 10px;
}

@media only screen and (max-width: 768px) {
  .btn-theme span {
    display: none;
  }
  .btn-theme i {
    margin: 0;
  }
}

.btn-theme:hover {
  color: white;
  transform: translateY(-2px);
  background: linear-gradient(90.12deg, #ac2ff1 99.9%, #7973fe 0.08%);
}

.btn-theme.btn-sm {
  padding: 3px 30px;
}

.btn-theme.btn-lg {
  padding: 10px 40px;
}

.btn-theme.btn-square {
  border-radius: 8px;
  padding: 5px;
}

.btn-theme.btn-35 {
  width: 35px;
  height: 35px;
}

.btn-theme.btn-35 i {
  margin: 0;
}

.modal .modal-content {
  box-shadow: none;
  border: 0;
}

.modal .modal-content .modal-header {
  border-bottom: 1px solid #f1f3f6;
}

.modal .modal-content .modal-header .modal-title.h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #3e3e3e;
}

.modal .modal-content .modal-footer {
  border-top: 1px solid #f1f3f6;
}

.modal .modal-content .modal-form {
  margin-bottom: 15px;
}

.modal .modal-content .modal-form label {
  margin-left: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #3e3e3e;
}

.loading {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 2000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 2000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-select {
  background-color: transparent;
  border-radius: 24px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='40' height='60' viewBox='0 0 4 8'%3e%3cpath fill='%23343a40' d='M6.99999 5.17192L11.95 0.221924L13.364 1.63592L6.99999 7.99992L0.635986 1.63592L2.04999 0.221924L6.99999 5.17192Z'/%3e%3c/svg%3e") no-repeat right 1rem center/30px 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e7e7e8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: #848484;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  text-align: center;
  color: white;
}

input:checked + .slider {
  background-color: #e7e7e8;
}

input:checked + .slider:before {
  content: "\2713";
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #64e764;
}

input:focus + .slider {
  box-shadow: 0 0 1px white;
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    width: 80px;
    -webkit-appearance: none;
    background-color: #f1f3f6;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #ac2ff1;
    margin-top: -1px;
    background-color: #ad2ff136;
  }
  input[type="range"]::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    height: 15px;
    margin-top: -3px;
    cursor: ew-resize;
    background: #ac2ff1;
    box-shadow: 1px 0px 10px #ac2ff1;
    border-radius: 50%;
  }
}

/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #ac2ff1;
}

input[type="range"]::-moz-range-track {
  background-color: #eaedf2;
}

/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #ac2ff1;
}

input[type="range"]::-ms-fill-upper {
  background-color: #f1f3f6;
}

.input-group.with-range .input-group-text {
  margin: 0px !important;
}

.input-group .custom-select {
  border-radius: 8px;
  background-color: #f1f3f6;
  border: 1px solid #e6e9f0;
  box-shadow: none;
}

.input-group .input-group-append {
  margin-left: -1px;
}

.input-group .input-group-append .input-group-text {
  background-color: #eaedf2;
  border: 1px solid #e6e9f0;
}

.input-group .input-group-append .input-group-text.theme {
  background: #7973fe;
  border: 1px solid #7973fe;
  color: #fff;
  border-radius: 8px;
}

.input-group .input-group-prepend {
  margin-left: -1px;
}

.input-group .input-group-prepend .input-group-text {
  background-color: #eaedf2;
  border: 1px solid #e6e9f0;
}

.input-group .input-group-prepend .input-group-text.theme {
  background: #7973fe;
  border: 1px solid #7973fe;
  color: #fff;
  border-radius: 8px;
}

.table td,
.table th {
  vertical-align: middle;
  font-family: Roboto;
  font-style: normal;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
}

.table th {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.form-input-field {
  border: 1px solid #e6e9f0;
}

ul.bullet-theme {
  list-style: none;
}

ul.bullet-theme li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #3e3e3e;
  margin-bottom: 15px;
  position: relative;
}

ul.bullet-theme li:hover {
  color: #3e3e3e;
}

ul.bullet-theme li::before {
  content: "\2022";
  font-size: 40px;
  line-height: 0px;
  color: #ac2ff1;
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: -25px;
}

ul.bullet-theme.old li,
ul.bullet-theme.old li::before {
  color: #b5b5c3;
}

ul.bullet-theme.old li:hover {
  color: #3e3e3e;
}

.border-bottom {
  border-bottom: 1px solid #f1f3f6 !important;
}

a.dropdown-item:hover {
  color: #000 !important;
}

.brand-link:hover {
  color: white !important;
}

.brand-link span:hover {
  color: white;
}

.nav-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  color: #3e3e3e;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: inherit;
}

.dropdown-item.active:hover,
.dropdown-item:active:hover {
  color: white;
}

.btn-theme-outline {
  background-color: white;
  border: 1px solid #ac2ff1;
  border-radius: 24px;
  color: #ac2ff1;
}

.btn-theme-outline i {
  margin-left: 10px;
}

.btn-theme-outline:hover {
  color: white;
  transform: translateY(-3px);
  background: linear-gradient(90.12deg, #ac2ff1 99.9%, #7973fe 0.08%);
}

.box-shadow-left {
  box-shadow: 12px 0px 20px rgba(0, 0, 0, 0.1);
}

.height-100-vh {
  height: 100vh;
}

.login-sidebar {
  text-align: center;
  width: 75%;
  margin: auto;
}

.login-sidebar .logo {
  width: 80px;
  padding-bottom: 0px;
  margin-bottom: 25px;
}

.login-sidebar h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 37px;
  color: #3e3e3e;
}

.flex-1 {
  flex: 1;
}

.login-right {
  margin: auto;
}

.pagination .page-item {
  border: none;
  background-color: transparent;
}

.pagination .page-item .page-link {
  border: none;
  background-color: transparent;
  color: #3e3e3e;
  cursor: pointer;
  font-weight: bold;
}

.pagination .page-item .page-link.active {
  color: #7973fe;
  cursor: default;
  font-size: 20px;
  margin-top: -3px;
}

.pagination .page-item .page-link.prev, .pagination .page-item .page-link.next {
  background: #7973fe;
  box-shadow: 0px 4px 4px rgba(121, 115, 254, 0.3);
  border-radius: 4px;
  color: #fff;
}

.nav.nav-sidebar .nav-item .nav-link .nav-icon.fa {
  font-size: 16px;
  margin-right: 10px;
}

.btn-primary {
  background-color: #453dfd !important;
  border-color: #453dfd !important;
}

.btn-link {
  color: #453dfd !important;
}

.btn-link:focus {
  border: 0;
  box-shadow: none;
}

.modal .modal-content .modal-form label {
  line-height: 27px;
}

.Toastify__toast {
  border-radius: 5px !important;
  min-height: auto !important;
}

.Toastify__toast.Toastify__toast--default {
  background: linear-gradient(90.12deg, #ac2ff1 0.08%, #7973fe 99.9%) !important;
  border-radius: 5px !important;
  color: #fff !important;
  align-items: center !important;
}

.Toastify__toast .Toastify__close-button {
  color: #fff !important;
  align-self: center;
}

.modal-footer {
  margin-top: 0 !important;
}

.dropdown-header {
  text-align: left !important;
}

.alert a {
  color: #453dfd;
}

.text-theme {
  color: #453dfd !important;
}

.p-100 {
  padding: 80px !important;
}

.loaderClass {
  background: transparent;
  height: 85vh;
}

.loaderClassCard {
  background: transparent;
  position: relative;
  height: 100%;
}

.loaderClassCard .p-2 {
  position: absolute;
  top: 30%;
  height: 41px;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px #9880ff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #9880ff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #9880ff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
}

.bg-dark-square {
  background-color: #3e3e3e;
  color: rgba(255, 255, 255, 0.795);
  width: 25px;
  height: 25px;
  border-radius: 2px;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
}

.text-muted-x {
  color: #b5b5c3 !important;
}

.add-card:hover i {
  color: #453dfd !important;
}

.card-logo-float {
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 100;
}
