a.nav-link.menu-opened,
a.nav-link.menu-opened:hover {
    background: #FFD302;
    color: #000;
    border-radius: 0px 50px 50px 0px;
    margin-right: 10px;
}

a.nav-link:hover {
    border-radius: 0px 50px 50px 0px;
    margin-right: 0px;
}

.sidebar {
    padding-left: 0;
}

aside#sidebar {
    background: #3E3E3E;
}

nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
    background: white;
}

.dropdown.show.profile-dropdown {
    margin: auto;
    transform: translate(0%, 0%);
    padding: 8px 15px;
    background: #F1F3F6;
    border-radius: 8px;
    color: #666669;
    cursor: pointer;
}

.dropdown-menu.show {
    box-shadow: 0px 8px 8px rgb(121 115 254 / 30%);
    border-radius: 8px;
    background: #f1f3f6;
}

.dropdown-menu.show {
    box-shadow: 0px 8px 8px rgba(121, 115, 254, 0.3);
    border-radius: 8px;
    background: #f1f3f6;
}

a.dropdown-item:hover {
    color: #3e3e3e;
}

.sidebar {
    margin-top: 2em;
}

.screen-title {
    line-height: 10px;
}

.section-container {
    padding: 15px 30px;
    height: 100%;
}

.theme-btn {
    background: linear-gradient(90.12deg, #AC2FF1 0.08%, #7973FE 99.9%);
    box-shadow: 0px 8px 8px rgba(158, 65, 245, 0.3);
    border-radius: 24px;
    color: white;
    padding: 11px 18px;
    text-decoration: none;
    width: max-content;
    border: none;
    cursor: pointer;
}

.theme-btn>.fa {
    font-size: 15px;
    margin-left: 10px;
}

.theme-btn:active {
    color: white;
}

.theme-btn:hover {
    color: white;
    text-decoration: none;
}

.theme-btn:focus {
    color: white;
    text-decoration: none;
    outline: none;
}

.server-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    max-width: 32%;
    margin: 0.4em;
}

@media only screen and (max-width: 768px) {
    .server-card {
        max-width: 100%;
        margin: 0.4em;
    }
}

.server-card-header {
    padding: 15px 15px;
    border-bottom: 1px solid #F1F3F6;
    padding-bottom: 0px;
}

.server-card-lebel>p {
    font-size: 13px;
    color: #B5B5C3;
}

.server-card-lebel>h5 {
    font-size: 25px;
}

.servers-container {
    padding: 10px 20px;
    padding-top: 0px;
}

.server-card-content {
    padding: 10px 10px;
}

.server-card-content>div :first-child {
    padding-right: 0px;
    text-align: right;
}

.server-card-content>div :last-child {
    text-align: left;
}

body {
    font-family: 'Roboto';
}

.form-group {
    position: relative;
}

select.form-input-field {
    width: 100%;
    padding: 9px 14px;
}

.form-group {
    position: relative;
}

.form-side-image-container {
    background-color: #F1F3F6;
    height: 100vh;
}

.login-form-container {
    padding-top: 2%;
}

button.form-action-btn {
    width: 80%;
    border: none;
    background: linear-gradient(90.12deg, #AC2FF1 0.08%, #7973FE 99.9%);
    box-shadow: 0px 8px 8px rgba(158, 65, 245, 0.3);
    border-radius: 24px;
    color: white;
    padding: 10px;
}

a.form-link {
    text-align: center;
    text-decoration-line: underline;
    color: #7973FE;
}

.input-icon {
    position: absolute;
    right: 2%;
    width: 38px;
    height: 38px;
}

.modal-form.form-group {
    margin-bottom: 0px;
}

.modal-footer {
    margin-top: 20px;
}

body {
    background: #f4f6f9;
}

.server-card-content>div>div>p {
    color: #7b7c84;
}

.stipe {
    width: 100%;
    padding: 3px 0px;
    text-align: center !important;
}

.server-card-content>div>div>p {
    font-size: medium;
}

.server-card-content>div>div>img {
    width: 40px;
}

.server-card-content.service>div>div {
    text-align: center;
}

.server-card-content.service>.row {
    margin: 15px 0px;
}

@import 'mystyles.css';