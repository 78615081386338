h1,
h2,
h3,
body {
    font-family: 'Roboto';
}

.form-input-field {
    padding: 7px 14px;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    color: #4c4c4c;
    background-color: #F1F3F6;
    border: none;
}

.input-icon {
    position: absolute;
    right: 2%;
    width: 38px;
    height: 38px;
}

.form-group {
    position: relative;
}

.main-container {
    overflow: hidden;
}

.form-side-image-container {
    background-color: #F1F3F6;
    height: 100vh;
}

.login-form-container {
    padding-top: 2%;
}

button.form-action-btn {
    width: 80%;
    border: none;
    background: linear-gradient(90.12deg, #AC2FF1 0.08%, #7973FE 99.9%);
    box-shadow: 0px 8px 8px rgba(158, 65, 245, 0.3);
    border-radius: 24px;
    color: white;
    padding: 10px;
}

a.form-link {
    text-align: center;
    text-decoration-line: underline;
    color: #7973FE;
}

.separator {
    width: 80%;
    height: 20px;
    border-bottom: 1px solid #747775;
    text-align: center;
    padding-top: 6px;
    margin: 20px auto;
}

.separator-text {
    font-size: 18px;
    background-color: #ffffff;
    padding: 0 15px;
    color: #747775;
}

a.form-subaction-btn {
    border: 1px solid #AD2DF1;
    box-sizing: border-box;
    border-radius: 24px;
    width: 80%;
    background: white;
    padding: 10px;
}