body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.form-card {
  box-shadow: 0 0 1px rgba(0,0,0,.125), -1px 2px 14px 5px rgba(0,0,0,.2);
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.spin{
  animation: 4s linear 0s infinite normal none running spin
}
.dot-red{
  width: 15px !important;
  height: 15px;
  background: red;
  border-radius: 50%;
  align-self: center;
  margin-right: 5px;
}
.dot-green{
  width: 15px !important;
  height: 15px;
  background: green;
  border-radius: 50%;
  align-self: center;
  margin-right: 5px;
}
.white{
  color:white !important
}
.cron-action{
  display: grid;
}
.cron-action > button {
  margin-top: 4px;
}
.container-fluid.p-0{
  background-color: #343a40;
}
ul#server-details-tab-header{
  border-bottom: 1px solid black;
  padding-bottom: 15px !important;

}
.hide {
  display: none !important;
}
.application_page_card_info , .nav-link{
  cursor: pointer;
}
.login_scrn{
	height:100vh;
}
